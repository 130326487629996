<template>
  <div class="row my-3 pe-5 p-4 position-relative" :class="{ campus: campus }">
    <div>
      <div class="title fs-4">
        {{ title }}
      </div>
      <div class="d-flex mt-1">
        <div class="location">
          {{ getLocations }}
        </div>
        <div class="vr mx-3 align-self-center h-100"></div>
        <div
          class="fw-bold"
          :class="{ 'color-orange': campus, 'color-default': !campus }"
        >
          {{ campus ? "校园招聘" : "社会招聘" }}
        </div>
      </div>
      <div class="hr"></div>
    </div>
    <div class="description text-muted fst-italic">
      {{ description }}
    </div>
    <router-link :to="link" class="stretched-link"></router-link>
  </div>
</template>

<script>
export default {
  name: "JobCard",
  computed: {
    getLocations() {
      return [].join.call(this.location || [], " ");
    },
  },
  props: {
    title: String,
    category: String,
    location: Array,
    campus: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: "#!",
    },
    description: String,
  },
};
</script>

<style scoped lang="scss">
.row {
  background-color: $--ft-white;
  height: 200px;
  transition: 0.2s;
  border-radius: 2rem;
}
.row:hover {
  box-shadow: 5px 0px 20px 0px rgba(0, 0, 0, 20%);
  transform: translateX(5px);
  border-left: 5px solid $--ft-primary-4;
}
.row.campus:hover {
  border-left: 5px solid $orange;
}
.description {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.tags > div {
  margin: 0 0.25rem;
}
.tags > div:first-of-type {
  margin-left: 0;
}
.title {
  font-weight: bolder;
}
.icon {
  bottom: 0.1rem;
}
.hr {
  display: none;
}
@media screen and (max-width: map-get($container-max-widths,sm)) {
  .vr {
    display: none;
  }
  .background {
    display: none;
  }
  .d-flex {
    flex-direction: column;
  }
  .row {
    height: 100%;
  }
  .hr {
    display: block;
  }
}
</style>
